import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WorkInfo from "../../components/work-info"
import { StaticImage } from "gatsby-plugin-image"
import ProjectCard from "../../components/project-card"
import RelevantSkin from "../../images/projects/relevant-skin/cover.jpg"
import Versed from "../../images/projects/versed/cover.jpg"

const aurisPage = () => (
  <Layout>
    <SEO title="Isa Lazo" />
    <WorkInfo 
      title="Isa Lazo"
      link="isalazo.com"
      team1="Bailey Latimer"
      team2="Jeremy Holsted"
      challenge="Clean skincare crafted from the purest elements of nature."
      solution="Clean skincare crafted from the purest elements of nature."/>

    <StaticImage class="mb-6" src="../../images/projects/isa-lazo/cover.jpg" alt="Isa Lazo Hero" aspectRatio={16/9} layout={"fullWidth"}/>

      <div class="columns-1 lg:columns-2">
        <StaticImage class="mb-6" src="../../images/projects/isa-lazo/about--mobile.jpg" alt="Isa Lazo About Mobile" layout={"fullWidth"} aspectRatio={4/5}/>
        <StaticImage class="mb-6" src="../../images/projects/isa-lazo/browser.jpg" alt="Isa Lazo Browser Preview" layout={"fullWidth"} aspectRatio={4/5}/>
      </div>
      <StaticImage class="mb-6" src="../../images/projects/isa-lazo/home--desktop.jpg" alt="Isa Lazo Home Preview" layout={"fullWidth"}/>
      <div class="columns-1 lg:columns-2">
        <StaticImage class="mb-6" src="../../images/projects/isa-lazo/pdp--mobile.jpg" alt="Isa Lazo Product Page Mobile" layout={"fullWidth"} aspectRatio={4/5}/>
        <StaticImage class="mb-20" src="../../images/projects/isa-lazo/home--mobile.jpg" alt="Isa Lazo Home Mobile Preview" layout={"fullWidth"} aspectRatio={4/5}/>
      </div>
   
      <div class="adv-gallery-v3 mb-20">
        <h4 className="color-contrast-higher mb-4 pl-8 lg:pl-0">Related</h4>
        <ul class="columns-1 lg:columns-2">
          <ProjectCard classes="" title="Versed Skin" url="versed-skin"  description="Revolutionizing the natural skincare industry with all-natural, vegan ingredients for cleansers, moisturizers, serums, masks." cover={Versed} role="Development" />

          <ProjectCard classes="" title="Relevant Skin" url="relevant-skin"  description="Science meets soul with a heaping dose of good vibes." cover={RelevantSkin} role="Development" />
        </ul>
      </div>


  </Layout>
)

export default aurisPage
